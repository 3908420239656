<template>


  <CRow>
   
   

    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <strong>Aviso</strong>
        </CCardHeader>

        <CCardBody>
 
          <CForm inline>
            <CRow>
              <CCol sm="12" style="text-align: left">
                 <!-- <ol>asa</ol> -->
                 <CAlert color="info">


               <li>
                 <b> Estimado aspirante, tus pagos están en proceso de validación, <b>en breve recibirás un correo electrónico</b> con tu acceso al sistema de la universidad (SAE UPB). 
                  </b>
                </li>

            
                
                <br/>
                </CAlert>
              </CCol>
            </CRow>

          </CForm>
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>


</template>

<script>


export default {

  name:'cargaMateriasInicio',
  components: { 
    // CTableWrapper
       },
  data () {
    return {
      id:localStorage.getItem('id'),
  
    }
  },
  created() {


  },
  methods: {



  }
}
</script>
